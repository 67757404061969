<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Reportes</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <router-link to="/reportes/clientes"
        ><span class="text-500 line-height-3"
          >Reporte de Clientes</span
        ></router-link
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Detalle</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12 border-2">
    <div
      class="
        flex
        align-items-center
        flex-column
        lg:justify-content-center lg:flex-row
        mb-4
      "
    >
      <h4><strong>DETALLE DE VENTAS DE CLIENTES</strong></h4>
    </div>
    <div class="mt-4">
      <DataTable
        ref="dtclienteventas"
        :value="ventas"
        key="id"
        :paginator="true"
        :rows="20"
        :rowHover="true"
        :filters="buscar"
        class="p-datatable-sm"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[20, 100, 500, 1000]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Ventas Clientes"
        responsiveLayout="scroll"
        stripedRows
        showGridlines
      >
      <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar..."
              />
            </span>
          </div>
        </template>
        <Column field="id_venta" header="ID" style="font-size: 1em; font-weight: bold;" :sortable="true" />
       <!--  <Column field="sucursal" header="SUCURSAL" /> -->
        <Column field="usuario" header="USUARIO" style="font-size: 1em;" />
        <Column field="cliente" header="CLIENTE" style="font-size: 1em;" />
        <Column field="nit" header="NIT/CI" style="font-size: 1em;" />
        <Column field="fecha_venta" header="F.VENTA" style="font-size: 1em;" />
        <Column field="codigo_origen" header="COD.ORIG." style="font-size: 1em;" />
        <Column field="producto" header="PRODUCTO/DESCRIPCIÓN" style="font-size: 1em; font-weight: bold;" />
        <Column field="cantidad" header="CANT." style="font-size: 1em;  text-align: right;" />
        <Column field="precio" header="P.UNIT." style="font-size: 1em; text-align: right;" />
        <Column field="descuento_producto" header="DESC." style="font-size: 1em; text-align: right;" />
        <Column field="total_cancelar" header="TOTAL" style="font-size: 1em; text-align: right;" />
        <Column field="observacion" header="OBSERVACIÓN" style="font-size: 1em; " />
      </DataTable>
    </div>
    <div
      class="
        mt-4
        flex
        align-items-end
        flex-column
        lg:justify-content-end lg:flex-row
      "
    >
      <Button @click="goBack">
        <i class="pi pi-arrow-left"></i>
        &nbsp;ATR&Aacute;S
      </Button>
    </div>
  </div>
</template>
<script>
import ClienteService from "@/service/ClienteService";
import { FilterMatchMode } from "primevue/api";
export default {
  data() {
    return {
      idCliente: this.$route.params.id,
      ventas: [],
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
    };
  },
  clienteService: null,
  created() {
    this.clienteService = new ClienteService();
  },
  mounted() {
    this.cargarVentas();
  },
  methods: {
    cargarVentas() {
      this.clienteService.showClienteVentas(this.idCliente).then((data) => {
        this.ventas = data.ventas;
      });
    },
    goBack() {
      this.$router.push("/reportes/clientes");
    },
  },
};
</script>